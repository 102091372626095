function switchTheme(theme) {
    var links = document.getElementsByTagName('link');
    for (var i = 0; i < links.length; i++) {
        var link = links[i];
        if (link.rel.indexOf('stylesheet') !== -1 && link.title) {
            // Only disable the stylesheet links that have a title
            link.disabled = link.title !== theme;
        }
    }
}
